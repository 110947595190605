export const PAGINATION_LIMIT = 250;

export const EXECUTIVE_ROLE_ID = 1
export const MANAGEMENT_ROLE_ID = 2
export const FRONTLINE_ROLE_ID = 3
export const ASSOCIATE_ROLE_ID = 4
export const CUSTOMER_ROLE_ID = 5
export const ANONYMOUS_ROLE_ID = 0

export const DEFAULT_CLIENT_LIST_SORT_BY_VALUE = "last_name,ASC";
export const DEFAULT_CLIENT_STATUS_FILTER_OPTION = 1; // 1=Active

export const UNASSIGNED_CUSTOMER_TYPE_OF_ID = 1
export const RESIDENTIAL_CUSTOMER_TYPE_OF_ID = 2
export const COMMERCIAL_CUSTOMER_TYPE_OF_ID = 3

export const UNASSIGNED_ASSOCIATE_TYPE_OF_ID = 1
export const RESIDENTIAL_ASSOCIATE_TYPE_OF_ID = 2
export const COMMERCIAL_ASSOCIATE_TYPE_OF_ID = 3

export const CLIENT_PHONE_TYPE_LANDLINE = 1
export const CLIENT_PHONE_TYPE_MOBILE = 2
export const CLIENT_PHONE_TYPE_WORK = 3

export const CLIENT_ORGANIZATION_TYPE_UNKNOWN = 1
export const CLIENT_ORGANIZATION_TYPE_PRIVATE = 2
export const CLIENT_ORGANIZATION_TYPE_NON_PROFIT = 3
export const CLIENT_ORGANIZATION_TYPE_GOVERNMENT = 4
